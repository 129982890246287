import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/app/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/Init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RouteChangeListener"] */ "/app/src/providers/RouteChangeListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/stores/localization/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/stores/session/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/stores/session/useSession.ts");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
