'use client';

import * as gtm from '@/lib/GTM';
import { SessionContext } from '@/stores/session/context';
import {
  SessionProps,
  SessionStore,
  createSessionStore,
} from '@/stores/session/store';
import React, { useEffect, useRef } from 'react';

type SessionProviderProps = React.PropsWithChildren<{ session: SessionProps }>;

export default function SessionProvider({
  children,
  session,
}: SessionProviderProps) {
  const storeRef = useRef<SessionStore>(undefined);
  if (!storeRef.current) {
    storeRef.current = createSessionStore(session);
  }

  useEffect(() => {
    if (session?.user) {
      // TODO: leanplum is not defined
      // leanplum.saveUser(session.user);
      gtm.addEmail(session.user.email);
    }
  }, [session]);

  return (
    <SessionContext.Provider value={storeRef.current}>
      {children}
    </SessionContext.Provider>
  );
}
